<template>
    <h5>Изменения сохранены</h5>
</template>

<script>
export default {
    async mounted() {
        setTimeout(() => {
            window.parent.postMessage('close-modal-success', '*');
        }, 1000)
    }
}
</script>

<style scoped>

</style>
