import IdIndexer from "@/utils/IdIndexer";

class MoleculeInteractionsManager extends IdIndexer {
    constructor(description, molecule_manager, condition_manager) {
        description.forEach(interaction => {
            interaction.molecules = interaction.molecules.map(molecule_id => molecule_manager.get_by_id(molecule_id))
            interaction.conditions = interaction.conditions.map(condition_id => condition_manager.get_by_id(condition_id))
        })

        super(description)
    }
}

export default MoleculeInteractionsManager