<template>
    <div>
        <loading v-if="!loaded"/>
        <MoleculeChooser v-if="loaded && stage === 1"/>
        <RecommendationScreen v-if="loaded && stage === 2"/>
        <DosingScreen v-if="loaded && stage === 3"/>
        <DoneScreen v-if="loaded && stage === 4"/>
    </div>
</template>

<script>


import Loading from "@/components/common/Loading.vue";
import MoleculeChooser from "@/components/recommendations/MoleculeChooser.vue";
import RecommendationScreen from "@/components/recommendations/RecommendationScreen.vue";
import DosingScreen from "@/components/recommendations/DosingScreen.vue";
import DoneScreen from "@/components/recommendations/DoneScreen.vue";

export default {
    name: 'RecommendationManager',
    components: {DosingScreen, RecommendationScreen, Loading, MoleculeChooser, DoneScreen},
    data() {
        return {
            stage: 1,
            loaded: false
        }
    },
    methods: {
        next_stage: function () {
            this.stage += 1
        },
        previous_stage: function () {
            this.stage -= 1
        },
        load_done: function () {
            this.loaded = true
        }
    },
    async mounted() {
        this.event_bus.on('next_stage', this.next_stage)
        this.event_bus.on('previous_stage', this.previous_stage)
        this.event_bus.on('loaded', this.load_done)

        if (this.mode !== 'full') {
            this.stage = 3
        }
    }
}
</script>
