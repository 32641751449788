<template>
    <div class="row" v-if="molecule_manager">

        <div class="col">
            <h3 class="my-3">Шаг 1. Выберите вещества, которые пациент уже принимает</h3>

            <p class="my-2">В поиске ниже можно найти список веществ, с которыми может взаимодействовать назначаемая
                терапия. Если
                вещество есть в списке, отметьте его галочкой.</p>
            <p class="my-2">Если вещества в списке нет, значит данных о взаимодействии с ним пока нет. После выбора всех
                веществ, нажмите <strong>далее</strong>.</p>

            <div v-if="molecule_manager.actual().length > 0" class="card mt-3">
                <div class="card-body">
                    <h6 class="mb-3">Выбранные вещества</h6>

                    <div class="form-group my-2"
                         v-for="molecule in molecule_manager.actual()"
                         :key="molecule.id">
                        <input v-model="molecule_manager.enabled[molecule.id]" type="checkbox"
                               class="form-check-inline" data-bs-toggle="collapse"><span
                        class="form-check-label">{{
                            molecule.title
                        }}</span>
                    </div>
                </div>
            </div>

            <button class="btn btn-success my-3" @click="done">Далее</button>

            <h6>Поиск по каталогу</h6>

            <input type="text" class="form-control" v-model="search" placeholder="Поиск..."/>

            <div v-if="search" class="my-3">
                <div class="form-group my-2"
                     v-for="molecule in this.search_related_molecules"
                     :key="molecule.id">
                    <input v-model="molecule_manager.enabled[molecule.id]" type="checkbox"
                           class="form-check-inline" data-bs-toggle="collapse"><span
                    class="form-check-label">{{
                        molecule.title
                    }}</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
            molecule_manager: undefined,
            search: ""
        }
    },
    methods: {
        done: function () {
            this.event_bus.emit('next_stage')
        }
    },
    computed: {
        search_related_molecules: function () {
            const searcher = (molecule, q) => {
                const parts = molecule.title.split(" ");
                const query = q.toLowerCase()
                return parts.filter(part => part.toLowerCase().startsWith(query)).length > 0 || molecule.title.toLowerCase().startsWith(query)
            }

            return this.molecule_manager.all().filter(m => searcher(m, this.search))
        }
    },
    async mounted() {
        this.molecule_manager = this.state.molecules
    }
}
</script>

<style scoped>

</style>
