import {intersection, timestamp_ms} from "@/utils/helpers";

class ConditionRecommender {
    constructor(group_interactions_manager, molecule_interactions_manager, groups_manager, molecule_manager) {
        this._group_interactions = group_interactions_manager
        this._molecule_interactions = molecule_interactions_manager
        this._groups = groups_manager
        this._molecules = molecule_manager

        this._last_refresh = 0;

        this._actual_molecule_interactions = [];
        this._actual_group_interactions = [];
    }

    _refreshIfShould() {
        if (timestamp_ms() - this._last_refresh > 300) {
            this._refresh()
            this._last_refresh = timestamp_ms()
        }
    }

    _refresh() {
        const actual_molecules = new Set(this._molecules.actual())
        const actual_groups = new Set(this._groups.actual())

        this._actual_molecule_interactions = this._molecule_interactions.all().filter(interaction => intersection(new Set(interaction.molecules), actual_molecules).size !== 0);
        this._actual_group_interactions =  this._group_interactions.all().filter(interaction => intersection(new Set(interaction.groups), actual_groups).size !== 0);
    }

    _get_actual_group_interactions(condition) {
        return this._actual_group_interactions.filter(interaction => interaction.conditions.includes(condition))
    }

    _get_actual_molecule_interactions(condition) {
        return this._actual_molecule_interactions.filter(interaction => interaction.conditions.includes(condition))
    }

    getAll(condition) {
        this._refreshIfShould()

        const recommendations = {
            "DENY": [],
            "RELATIVE_DENY": [],
            "RELATIVE_RECOMMEND": [],
            "RECOMMEND": []
        }

        this._get_actual_group_interactions(condition).forEach(ia => {
            recommendations[ia.recommendation].push(ia)
        })

        this._get_actual_molecule_interactions(condition).forEach(ia => {
            recommendations[ia.recommendation].push(ia)
        })

        return recommendations
    }

    colorize(condition) {
        const recommendations = this.getAll(condition)

        if (recommendations["DENY"].length > 0) {
            return "red"
        }
        if (recommendations["RELATIVE_DENY"].length > 0) {
            return "yellow"
        }
        if (recommendations["RELATIVE_RECOMMEND"].length > 0) {
            return "light-green"
        }
        if (recommendations["RECOMMEND"].length > 0) {
            return "green"
        }

        return ""
    }
}

export default ConditionRecommender