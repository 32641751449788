import {reactive} from "vue";
import State from "@/models/State";
import event_bus from "@/event_bus";
import api from "@/api"


const state = reactive(new State(api))

const resources = {
    event_bus: event_bus,
    api: api,
    state: state,
}

export default resources