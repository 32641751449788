import {intersection} from "@/utils/helpers";

class PressureRecommender {
    constructor(contract, conditions_manager) {
        this._contract = contract
        this._conditions = conditions_manager
        this._conditions_cache = this._conditions.actual()

        this._cached = {}

        this._cached['max_systolic_pressure'] = this._contract.targets.max_systolic_pressure
        this._cached['min_systolic_pressure'] = this._contract.targets.min_systolic_pressure

        this._cached['max_diastolic_pressure'] = this._contract.targets.max_diastolic_pressure
        this._cached['min_diastolic_pressure'] = this._contract.targets.min_diastolic_pressure

        this._cached['max_pulse'] = this._contract.targets.max_pulse
        this._cached['min_pulse'] = this._contract.targets.min_pulse

    }

    _resetPressure() {
        this._contract.targets.max_systolic_pressure = this._cached['max_systolic_pressure']
        this._contract.targets.min_systolic_pressure = this._cached['min_systolic_pressure']

        this._contract.targets.max_diastolic_pressure = this._cached['max_diastolic_pressure']
        this._contract.targets.min_diastolic_pressure = this._cached['min_diastolic_pressure']
    }

    _resetPulse() {
        this._contract.targets.max_pulse = this._cached['max_pulse']
        this._contract.targets.min_pulse = this._cached['min_pulse']
    }

    update() {
        let actual_ids = this._conditions.actual().map(c => c.id)
        let cached_ids = this._conditions_cache.map(c => c.id)

        // 33 - почки;
        // давление
        if (actual_ids.includes(33) && !cached_ids.includes(33)) {
            this._contract.targets.max_systolic_pressure = 140
            this._contract.targets.min_systolic_pressure = 130
        }

        if (!actual_ids.includes(33) && cached_ids.includes(33)) {
            this._resetPressure()
        }

        // пульс
        // 31 113 15
        const current = intersection(new Set(actual_ids), new Set([31, 113, 15]))
        const cached = intersection(new Set(cached_ids), new Set([31, 113, 15]))

        if (current.size !== 0 && cached.size === 0) {
            this._contract.targets.max_pulse = 70
        }


        if (current.size === 0 && cached.size !== 0) {
            this._resetPulse()
        }

        this._conditions_cache = this._conditions.actual()
    }


}

export default PressureRecommender