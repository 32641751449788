<template>
    <div>
        <loading v-if="!loaded"/>
        <div v-else class="my-3">
            <h3>{{ molecule.title }}</h3>

            <table class="table table-striped my-3">
                <tbody>
                <tr>
                    <th>ATX:</th>
                    <td>{{ molecule.atx }}</td>
                </tr>
                <tr v-if="molecule.group">
                    <th>Группа:</th>
                    <td>{{ molecule.group.title }}<span class="text-muted"
                                                        v-if="molecule.additions"> {{ molecule.additions }}</span></td>
                </tr>
                <tr v-if="molecule.mkb">
                    <th>Коды МКБ:</th>
                    <td>
                        <ul>
                            <li v-for="(value, code) in molecule.mkb" :key="code"><strong>{{ code }}:</strong> {{
                                    value
                                }}
                            </li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>

            <DescriptionCard :info="molecule.dosage_description" title="Режим дозирования"/>
            <DescriptionCard :info="molecule.indication" title="Показания к применению"/>
            <DescriptionCard :info="molecule.influence" title="Фармакологическое действие"/>
            <DescriptionCard :info="molecule.interaction" title="Лекарственное взаимодействие"/>
            <DescriptionCard :info="molecule.kinetics" title="Фармакокинетика"/>
            <DescriptionCard :info="molecule.side_effects" title="Побочные эффекты"/>


            <h5 class="my-3">Противопоказания</h5>

            <div class="card">
                <div class="card-body">
                    <div v-if="molecule.contra">
                        <h6>Общие</h6>

                        <ParagraphSeparator :text="molecule.contra"></ParagraphSeparator>
                    </div>

                    <div v-if="molecule.old">
                        <h6>Применение у пожилых пациентов</h6>

                        <ParagraphSeparator :text="molecule.old"></ParagraphSeparator>
                    </div>

                    <div v-if="molecule.preg_lact">
                        <h6>Применение при беременности и кормлении грудью</h6>

                        <ParagraphSeparator :text="molecule.preg_lact"></ParagraphSeparator>
                    </div>

                    <div v-if="molecule.hepato">
                        <h6>Применение при нарушениях функции печени</h6>

                        <ParagraphSeparator :text="molecule.hepato"></ParagraphSeparator>
                    </div>

                    <div v-if="molecule.renal">
                        <h6>Применение при нарушениях функции почек</h6>

                        <ParagraphSeparator :text="molecule.renal"></ParagraphSeparator>
                    </div>

                    <div v-if="molecule.child">
                        <h6>Применение у детей</h6>

                        <ParagraphSeparator :text="molecule.child"></ParagraphSeparator>
                    </div>
                </div>
            </div>

            <DescriptionCard :info="molecule.special" title="Особые указания"/>

        </div>
    </div>
</template>

<script>


import Loading from "@/components/common/Loading.vue";
import ParagraphSeparator from "@/components/common/Paragrapher.vue";
import DescriptionCard from "@/components/common/DescriptionCard.vue";


export default {
    components: {DescriptionCard, ParagraphSeparator, Loading},
    props: ['id'],
    name: "MoleculeDescription",
    data() {
        return {
            molecule: undefined,
            loaded: false
        }
    },
    methods: {},
    async mounted() {
        this.api.library.get_molecule(this.id).then(molecule => {
            this.molecule = molecule
            this.loaded = true
        })
    }
}
</script>
