import api_utils from "../api_utils";

class LibraryActions extends api_utils.ActionGroup {
    constructor(http_client, token) {
        super(http_client, token);
    }

    async get() {

        let query = {
            api_token: this.token
        }

        let result;

        try {
            result = await this.client.get('/objects', query);
        } catch (e) {
            console.log(e)
            throw new Error("Ошибка соединения с сервером.")
        }

        let expectedErrors = []

        api_utils.checkForErrors(result, expectedErrors)

        return result.data

    }

    async get_molecule(id) {

        let query = {
            api_token: this.token
        }

        let result;

        try {
            result = await this.client.get('/objects/molecule/' + id, query);
        } catch (e) {
            console.log(e)
            throw new Error("Ошибка соединения с сервером.")
        }

        let expectedErrors = []

        api_utils.checkForErrors(result, expectedErrors)

        return result.data

    }
}

export default LibraryActions