<script setup>

</script>

<template>
    <p :class="classname" v-for="(paragraph, i) in paragraphs" :key="i"><small v-if="small">{{ paragraph }}</small><span v-else>{{ paragraph }}</span></p>
</template>

<script>
export default {
    props: ['text', 'classname', 'small'],
    name: "ParagraphSeparator",
    data() {
        return {
            paragraphs: []
        }
    },
    watch: {
        text: function (newVal) {
            if (newVal !== undefined) {
                this.paragraphs = newVal.split('\n')
            } else {
                this.paragraphs = []
            }

        }
    },
    mounted() {
        if (this.text) {
            this.paragraphs = this.text.split('\n')
        }

    }
}
</script>