<template>
  <div>
    <h1 style="margin-top: 15%;"></h1><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: white; display: block; shape-rendering: auto;" width="121px" height="121px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">\n' + '<circle cx="50" cy="50" fill="none" stroke="#24a8b4" stroke-width="5" r="25" stroke-dasharray="117.80972450961724 41.269908169872416">\n' + '  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>\n' + '</circle></svg><h6 style="text-align: center; color: #24a8b4; font-family: sans-serif;">Загрузка...</h6>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner"
}


</script>

<style scoped>

</style>
