import HttpClient from "@/utils/http_client";
import LibraryActions from "@/api/action_groups/library";
import ContractActions from "@/api/action_groups/contract";
import {getCookie, setCookie} from "@/utils/helpers";

class ApiClient {
    constructor(host, emitter) {
        let client = new HttpClient(host + '/api', emitter)

        let token = window.TOKEN

        if (!token) {
            token = getCookie('cdss-token')
        }

        if (!token) {
            token = prompt("Token?");
            setCookie('cdss-token', token, 10)
        }

        console.log("Starting app with token", token)

        this.library = new LibraryActions(client, token)
        this.contract = new ContractActions(client, token)
    }
}

export default ApiClient
