class IdIndexer {
    constructor(description) {
        this._index = {}
        this._objects = description

        description.forEach(object => {
            this._index[object.id] = object
        })
    }

    get_by_id(id) {
        return this._index[id]
    }

    all() {
        return this._objects
    }

    search(by, value) {
        return this.all().filter(element => element[by] === value)
    }
}

export default IdIndexer