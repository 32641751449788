<script setup>

</script>

<template>
    <button @click="activate" class="btn" :class="class_name" :disabled="disabled && activated">
        <slot v-if="!disabled || !activated"></slot>
        <span v-else>Загрузка...</span>
    </button>
</template>

<script>
export default {
    name: 'LoadingButton',
    props: ['class_name'],
    data() {
        return {
            disabled: false,
            activated: false,
        }
    },
    methods: {
        enable: function () {
            this.disabled = false
            this.activated = false
        },
        disable: function () {
            this.disabled = true
        },
        activate: function () {
            this.activated = true
        }

    },
    async mounted() {
        this.event_bus.on('http_start', this.disable)
        this.event_bus.on('http_end', this.enable)
    }
}
</script>