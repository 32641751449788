<template>
    <h3 class="my-3">Шаг 2. Выберите группы и вещества</h3>

    <button class="btn btn-warning my-3" @click="back">Назад</button>

    <button class="btn btn-success my-3 mx-1" @click="done">Далее</button>

    <div class="alert alert-warning" v-if="warning_text">
        {{ warning_text }}
    </div>

    <div class="row" v-if="state.contract">
        <WarningPanels/>
    </div>

    <div class="row mb-5" v-if="state.contract">

        <div class="col col-lg-5">

            <div
                v-for="(macro_group, i) in [{title: 'Группы', is_additional: false}, {title: 'Дополнительно', is_additional: true}]"
                :key="i">

                <h4 class="my-3">{{ macro_group.title }}</h4>

                <div class="accordion">

                    <div class="accordion-item"
                         v-for="(group, i) in state.library.groups.filter(g => g.is_additional === macro_group.is_additional)"
                         :key="group.id">
                        <h2 class="accordion-header">
                            <div class="accordion-button" :class="state.group_recommender.colorize(group)">
                                <input @change="toggleGroup(group)" v-model="state.groups.enabled[group.id]"
                                       type="checkbox"
                                       class="form-check-inline" data-bs-toggle="collapse"
                                       :data-bs-target="'#group_collapse_' + i + '_' + macro_group.is_additional"
                                       aria-expanded="true"
                                       :aria-controls="'#group_collapse_' + i + '_' + macro_group.is_additional"/><span
                                class="form-check-label blacky">{{
                                    group.title
                                }}</span>
                            </div>
                        </h2>
                        <div :id="'group_collapse_' + i + '_' + macro_group.is_additional"
                             class="accordion-collapse collapse"
                             :class="{'show': state.groups.enabled[group.id]}">
                            <div class="accordion-body p-0">
                                <table class="table m-0">
                                    <tbody>
                                    <tr v-for="molecule in state.molecules.search('group_id', group.id)"
                                        :key="molecule.id">
                                        <td class="px-3">
                                            <div class="d-flex flex-row px-1"
                                                 :class="state.molecule_recommender.colorize(molecule)">
                                                <div class="flex-grow-1"><input
                                                    v-model="state.molecules.enabled[molecule.id]" type="checkbox"
                                                    class="form-check-inline">
                                                    <span class="form-check-label">
                                    <router-link :to="{ name: 'molecule', params: {id: molecule.id}}"
                                                 target="_blank">{{ molecule.title }}</router-link>
                                        <span class="text-muted" v-if="molecule.additions">&ensp;{{
                                                molecule.additions
                                            }} </span><span
                                                        v-if="molecule.is_vital"><img
                                                        :src="host + '/ico/vital.png'" title="Входит в ЖНВЛП"
                                                        style="height: 21px;"
                                                        class="ms-1"/></span>

                                    </span></div>

                                                <div>
                                            <span v-for="limit in molecule.limits" :key="limit.code"><img
                                                :src="host + '/ico/limits/' + limit.icon" style="height: 21px;"
                                                :title="limit.title"
                                                class="ms-1"/></span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div v-if="state.molecules.actual().filter(m => !m.group_id).length > 0">
                <h4 class="my-3">Прочая терапия</h4>

                <div class="card">
                    <div class="card-body">
                        <div class="form-group my-2"
                             v-for="molecule in state.molecules.actual().filter(m => !m.group_id)"
                             :key="molecule.id" :class="state.molecule_recommender.colorize(molecule)">
                            <input v-model="state.molecules.enabled[molecule.id]" type="checkbox"
                                   class="form-check-inline" data-bs-toggle="collapse"><span
                            class="form-check-label">{{
                                molecule.title
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="col col-lg-7">
            <h4 class="my-3">Состояния</h4>

            <div class="row">
                <div class="col-lg-6" v-for="(part, i) in make_groups(general_conditions)" :key="i">
                    <div class="form-group" v-for="condition in part" :key="condition.id">
                        <input @change="state.pressure_recommender.update()"
                               v-model="state.conditions.enabled[condition.id]" type="checkbox"
                               class="form-check-inline"><span
                        class="form-check-label" :class="state.condition_recommender.colorize(condition)">{{
                            condition.title
                        }}</span>
                    </div>
                </div>

                <hr class="mt-3">

                <div class="col-lg-6" v-for="(part, i) in make_groups(additional_conditions)" :key="i">
                    <div class="form-group" v-for="condition in part" :key="condition.id">
                        <input @change="state.pressure_recommender.update()"
                               v-model="state.conditions.enabled[condition.id]" type="checkbox"
                               class="form-check-inline"><span
                        class="form-check-label" :class="state.condition_recommender.colorize(condition)">{{
                            condition.title
                        }}</span>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <loading v-else></loading>
</template>

<script>


import Loading from "@/components/common/Loading.vue";
import WarningPanels from "@/components/recommendations/blocks/WarningPanels.vue";
import {difference} from "@/utils/helpers";


export default {
    components: {WarningPanels, Loading},
    data() {
        return {
            loaded: false,
            warning_text: ""
        }
    },
    methods: {
        done: function () {
            if (this.checkMoleculesIsSelected()) {
                this.event_bus.emit('next_stage')
            }
        },
        back: function () {
            this.event_bus.emit('previous_stage')
        },
        checkMoleculesIsSelected: function () {
            let selected_groups = new Set(this.state.groups.actual());
            let selected_molecule_groups = new Set(this.state.molecules.actual().filter(m => m.group_id).map(m => this.state.groups.get_by_id(m.group_id)))

            console.log("actual", selected_groups)
            console.log("molecules", selected_molecule_groups)

            if (selected_groups.size > selected_molecule_groups.size) {
                this.warning_text = "Не назначены вещества в группах: " + [...difference(selected_groups, selected_molecule_groups)].map(g => g.title).join(', ') + ". Назначьте вещества или снимите галочку с группы."
                return false
            } else {
                this.warning_text = ""
                return true
            }
        },
        toggleGroup(group) {
            if (this.state.groups.enabled[group.id] === false) {
                this.state.molecules.search('group_id', group.id).forEach(m => {
                    this.state.molecules.enabled[m.id] = false
                })
            }
            this.state.molecule_recommender._refresh()
        },
        make_groups(objects) {
            const array = objects;
            const midIndex = Math.floor(array.length / 2);
            const firstPart = array.slice(0, midIndex);
            const secondPart = array.slice(midIndex);

            return [firstPart, secondPart]
        }
    },
    computed: {
        general_conditions: function () {
            let conditions = []
            this.state.group_interactions.all().forEach(interaction => {
                conditions.push(...(interaction.conditions));
            })

            return [...new Set(conditions)]
        },
        additional_conditions: function () {
            let conditions = this.state.conditions.all().filter(c => !this.general_conditions.includes(c))
            return conditions.filter(c => this.state.conditions.has(c) || this.state.molecule_recommender.hasCondition(c))
        }
    },
    async mounted() {
        this.state.molecules.actual().forEach(m => {
            if (m.group_id) {
                this.state.groups.enabled[m.group_id] = true
            }
        })

        this.loaded = true
    }
}
</script>

<style scoped>

</style>
