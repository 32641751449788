<template>
    <div class="mb-5" v-if="state.library">
        <h3 class="my-3" v-if="mode === 'full'">Шаг 3. Дозировка</h3>

        <button class="btn btn-warning my-3" @click="back" v-if="mode === 'full'">Назад</button>

        <div class="row">
            <WarningPanels/>
        </div>

        <DosageEditor :was_sent="was_sent" :molecules="chosen_molecules" v-if="mode==='full' || mode==='dosage'"/>

        <TargetEditor :was_sent="was_sent" v-if="mode==='full' || mode==='targets'"/>

        <div v-if="mode==='full' || mode==='dosage'">
            <h5 class="my-3">Комментарий для пациента</h5>
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label">Комментарий</label>
                        <div class="col-sm-10">
                <textarea class="form-control"
                          v-model="comments"></textarea>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <LoadingButton class_name="btn btn-success my-3" @click="save(true)">Сохранить и уведомить пациента
        </LoadingButton>
        <LoadingButton class_name="btn btn-light my-3 ms-1" @click="save(false)">Сохранить без сообщения пациенту
        </LoadingButton>
    </div>

</template>

<script>

import WarningPanels from "@/components/recommendations/blocks/WarningPanels.vue";
import LoadingButton from "@/components/common/LoadingButton.vue";
import TargetEditor from "@/components/recommendations/blocks/TargetEditor.vue";
import DosageEditor from "@/components/recommendations/blocks/DosageEditor.vue";

export default {
    components: {DosageEditor, TargetEditor, LoadingButton, WarningPanels},
    data() {
        return {
            comments: "",
            send_to_patient: true,
            was_sent: false,
            warning_text: ""
        }
    },
    methods: {
        done: function () {
            this.event_bus.emit('next_stage')
        },
        back: function () {
            this.was_sent = false
            this.event_bus.emit('previous_stage')
        },
        hasErrors: function (prescription) {
            if (!prescription.dosage || !prescription.day_times) {
                console.log(prescription)
                return true
            }
            return false
        },
        checkTargets: function () {
            if (!this.state.contract.targets.min_pulse || !this.state.contract.targets.min_pulse || !this.state.contract.targets.min_systolic_pressure || !this.state.contract.targets.max_systolic_pressure || !this.state.contract.targets.min_diastolic_pressure || !this.state.contract.targets.max_diastolic_pressure) {
                return false
            } else {
                return true
            }
        },
        save: function (notify) {
            this.was_sent = true
            if (!this.chosen_molecules.some(m => this.hasErrors(this.state.contract.prescriptions[m.id])) && this.checkTargets()) {
                this.state.contract.save(this.comments, notify).then(() => {
                    this.event_bus.emit('next_stage')
                });
            }
        }
    },
    computed: {
        chosen_molecules: function () {
            return this.state.molecules.actual().filter(m => m.group_id !== null)
        }
    },
    mounted() {
        this.state.contract.updatePrescriptions()
    }
}
</script>

<style scoped>

</style>
