import {equality, intersection, union} from "@/utils/helpers";
import {Set} from "core-js/internals/set-helpers";

class ConditionRecommender {
    constructor(interactions_manager, conditions_manager, groups_manager) {
        this._interactions = interactions_manager
        this._groups = groups_manager
        this._conditions = conditions_manager
    }

    _actualGroups() {
        return new Set(this._groups.actual().filter(g => !g.is_additional))
    }

    _fillConditionRecommendations(group, recommendations) {
        const actual_conditions = new Set(this._conditions.actual())
        const actual_groups = this._actualGroups()

        const interactions_list = this._interactions.all()

        // ищем рекомендации и противопоказания к выбранным состояниям
        let search = undefined
        let condition_decision = undefined

        if (actual_groups.size === 0) {
            // если групп нет - ищем любые рекомендации
            search = ["DENY", "RECOMMEND", "RELATIVE_DENY", "RELATIVE_RECOMMEND"]
            condition_decision = interaction => intersection(new Set(interaction.conditions), actual_conditions).size !== 0 && interaction.groups.includes(group) && search.includes(interaction.recommendation)
        } else {
            // если выбраны группы, ищем единичные противопоказания
            search = ["DENY", "RELATIVE_DENY"]
            condition_decision = interaction => intersection(new Set(interaction.conditions), actual_conditions).size !== 0 && interaction.groups.includes(group) && interaction.groups.length === 1 && search.includes(interaction.recommendation)
        }

        const condition_interactions = interactions_list.filter(condition_decision)

        this._fillRecommendations(condition_interactions, recommendations)
    }

    _fillRecommendations(interactions, recommendations) {
        const actual_conditions = new Set(this._conditions.actual())

        interactions.forEach(ia => {
            // подчеркиваем рекомендуемые комбинации для состояний
            if (ia.groups.length > 1 && intersection(actual_conditions, new Set(ia.conditions)).size > 0) {
                recommendations["SPECIAL"].push(ia)
            }
            recommendations[ia.recommendation].push(ia)
        })
    }

    _removeDublicates(recommendations) {
        Object.keys(recommendations).forEach(key => {
            recommendations[key] = [...new Set(recommendations[key])]
        })
    }

    _fillForCurrentCombination(recommendations) {
        const actual_groups = this._actualGroups()
        const actual_conditions = new Set(this._conditions.actual())
        const interactions_list = this._interactions.all()

        actual_groups.forEach(group => this._fillConditionRecommendations(group, recommendations))

        const allow_list = interactions_list.filter(interaction => equality(new Set(interaction.groups), actual_groups) && ["RECOMMEND", "RELATIVE_RECOMMEND"].includes(interaction.recommendation))
        const deny_list = interactions_list.filter(interaction => equality(new Set(interaction.groups), actual_groups) && ["DENY", "RELATIVE_DENY"].includes(interaction.recommendation) && (intersection(new Set(interaction.conditions), actual_conditions).size > 0 || interaction.conditions.length === 0))

        this._fillRecommendations(allow_list, recommendations)
        this._fillRecommendations(deny_list, recommendations)



    }

    _fillPlusOne(group, recommendations) {
        const interactions_list = this._interactions.all()
        const actual_groups = this._actualGroups()

        const search_plus_one = interaction => union(new Set(interaction.groups), actual_groups).size === actual_groups.size + 1 && intersection(new Set(interaction.groups), actual_groups).size === actual_groups.size && interaction.groups.includes(group)
        const plus_one_interactions = interactions_list.filter(search_plus_one)

        this._fillRecommendations(plus_one_interactions, recommendations)
    }

    _emptyRecommendations() {
        return {
            "DENY": [],
            "RELATIVE_DENY": [],
            "RELATIVE_RECOMMEND": [],
            "RECOMMEND": [],
            "SPECIAL": []
        }
    }

    get(group) {
        const recommendations = this._emptyRecommendations()
        const actual_groups = this._actualGroups()

        if (actual_groups.has(group) && actual_groups.size >= 1) {
            this._fillForCurrentCombination(recommendations)
        }

        if (!actual_groups.has(group)) {
            this._fillConditionRecommendations(group, recommendations)

            if (actual_groups.size > 0 && actual_groups.size < 3) {
                this._fillPlusOne(group, recommendations)
            }
        }

        return recommendations
    }

    current() {
        const recommendations = this._emptyRecommendations()
        this._fillForCurrentCombination(recommendations)
        this._removeDublicates(recommendations)
        return recommendations
    }

    getCurrentList() {
        let recommendations = this.current()
        const actual_groups = this._actualGroups()

        if (recommendations["DENY"].length > 0) {
            return recommendations["DENY"]
        }
        if (recommendations["RELATIVE_DENY"].length > 0) {
            return recommendations["RELATIVE_DENY"]
        }
        if (actual_groups.size > 1 && recommendations["RELATIVE_RECOMMEND"].length > 0) {
            return [recommendations["RELATIVE_RECOMMEND"][0]]
        }
        if (actual_groups.size > 1 && recommendations["RECOMMEND"].length > 0) {
            return [recommendations["RECOMMEND"][0]]
        }

        return []
    }

    colorize(group) {
        const recommendations = this.get(group)
        let result = ""

        if (recommendations["RECOMMEND"].length > 0) {
            result = "green"
        }
        if (recommendations["RELATIVE_RECOMMEND"].length > 0) {
            result = "light-green"
        }
        if (recommendations["RELATIVE_DENY"].length > 0) {
            result = "yellow"
        }
        if (recommendations["DENY"].length > 0) {
            result = "red"
        }

        if (['green', 'light-green'].includes(result) && recommendations["SPECIAL"].length > 0) {
            result += " underlined"
        }

        return result
    }
}

export default ConditionRecommender