import {alphabetSorter} from "@/utils/helpers";
import ChecklistManager from "@/managers/ChecklistManager";
import GroupInteractionsManager from "@/managers/GroupInteractionsManager";
import MoleculeInteractionsManager from "@/managers/MoleculeInteractionsManager";
import ConditionRecommender from "@/managers/ConditionRecommender";
import GroupRecommender from "@/managers/GroupRecommender";
import MoleculeRecommender from "@/managers/MoleculeRecommender";
import Contract from "@/models/Contract";
import PressureRecommender from "@/managers/PressureRecommender";


class ApplicationState {
    constructor(api) {
        this._api = api
        this._objects = undefined

        this.contract = undefined
        this.library = undefined

        this.conditions = undefined
        this.groups = undefined
        this.molecules = undefined

        this.group_interactions = undefined
        this.molecule_interactions = undefined

        this.condition_recommender = undefined
        this.group_recommender = undefined
        this.molecule_recommender = undefined

        this.pressure_recommender = undefined
    }

    async init() {
        this.library = await this._api.library.get()
        this.contract = new Contract(await this._api.contract.get(), this._api, this)

        this.library.molecules.sort(alphabetSorter(m => m.title))
        this.library.conditions.sort(alphabetSorter(c => c.title))

        this.library.molecules.forEach(molecule => {
            molecule.limits = molecule.limits.map(lid => this.library.limits.find(l => l.id === lid))
        })

        this.conditions = new ChecklistManager(this.library.conditions)
        this.groups = new ChecklistManager(this.library.groups)
        this.molecules = new ChecklistManager(this.library.molecules)

        if (this.contract) {
            this.contract.molecules.forEach(m => {
                this.molecules.enabled[m.id] = true
            })

            this.contract.conditions.forEach(c => {
                this.conditions.enabled[c.id] = true
            })
        }

        this.group_interactions = new GroupInteractionsManager(this.library.interactions.group, this.conditions, this.groups)
        this.molecule_interactions = new MoleculeInteractionsManager(this.library.interactions.molecule, this.molecules, this.conditions)

        this.condition_recommender = new ConditionRecommender(this.group_interactions, this.molecule_interactions, this.groups, this.molecules)
        this.group_recommender = new GroupRecommender(this.group_interactions, this.conditions, this.groups)
        this.molecule_recommender = new MoleculeRecommender(this.molecule_interactions, this.molecules, this.conditions)

        this.pressure_recommender = new PressureRecommender(this.contract, this.conditions)
    }
}

export default ApplicationState