class Contract {
    constructor(description, api, state) {
        this.id = description.id
        this._description = description
        this._state = state
        this._api = api
        this.prescriptions = {};
        this.molecules = [];
        this.conditions = [];
        this.targets = description.targets

        this.molecules = this._description.molecules.map(molecule_id => state.library.molecules.find(m => m.id === molecule_id));

        this._description.prescriptions.forEach(prescription => {
            this.prescriptions[prescription.molecule_id] = prescription;
        })

        this.conditions = this._description.conditions.map(m_id => state.library.conditions.find(m => m.id === m_id));
    }

    async save(comments, notify_patient) {
        this.updatePrescriptions()
        const condition_ids = this._state.conditions.actual().map(c => c.id)
        const molecule_ids = this._state.molecules.actual().map(c => c.id)
        const prescriptions = Object.values(this.prescriptions).filter(p => p.is_legacy === false)
        return await this._api.contract.save(condition_ids, molecule_ids, prescriptions, comments, this.targets, notify_patient)
    }

    prescribedMolecules() {
        return this._state.molecules.actual().filter(m => m.group_id !== null)
    }

    updatePrescriptions() {
        Object.values(this.prescriptions).forEach(prescription => {
            prescription.is_legacy = true;
        });

        this.prescribedMolecules().forEach(molecule => {
            let prescription = this.prescriptions[molecule.id]
            if (prescription) {
                prescription.is_legacy = false;
            } else {
                this.prescriptions[molecule.id] = {
                    "molecule_id": molecule.id,
                    "dosage": molecule.start_dosage,
                    "day_times": molecule.day_times,
                    "is_legacy": false
                }
            }
        })
    }
}

export default Contract