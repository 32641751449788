<script setup>

import ParagraphSeparator from "@/components/common/Paragrapher.vue";
</script>

<template>
    <div v-if="info">
        <h5 class="my-3">{{ title }}</h5>
        <div class="card">
            <div class="card-body">
                <ParagraphSeparator :text="info"></ParagraphSeparator>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['info', 'title'],
    name: "DescriptionCard"
}
</script>