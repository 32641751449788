import {intersection, timestamp_ms} from "@/utils/helpers";

class MoleculeRecommender {
    constructor(interactions_manager, molecule_manager, condition_manager) {
        this._interactions = interactions_manager
        this._molecules = molecule_manager
        this._conditions = condition_manager
        this._last_refresh = 0;
        this._cross_interactions = [];
        this._condition_interactions = [];
        this._actual_molecule_interactions = [];
    }

    actual() {
        const actual_molecules = new Set(this._molecules.actual())
        const actual_conditions = new Set(this._conditions.actual())

        if (actual_molecules.size === 0) {
            return []
        }

        return this._interactions.all().filter(interaction => (intersection(new Set(interaction.molecules), actual_molecules).size === interaction.molecules.length) && (interaction.conditions.length === 0 || intersection(new Set(interaction.conditions), actual_conditions).size > 0))
    }

    _emptyRecommendations() {
        return {
            "DENY": [],
            "RELATIVE_DENY": [],
            "RELATIVE_RECOMMEND": [],
            "RECOMMEND": [],
            "SPECIAL": []
        }
    }

    _fillRecommendations(interactions, recommendations) {
        interactions.forEach(ia => {
            recommendations[ia.recommendation].push(ia)
        })
    }

    colorize(group) {
        const recommendations = this.get(group)
        let result = ""

        if (recommendations["RELATIVE_DENY"].length > 0) {
            result = "yellow"
        }
        if (recommendations["DENY"].length > 0) {
            result = "red"
        }

        if (['green', 'light-green'].includes(result) && recommendations["SPECIAL"].length > 0) {
            result += " underlined"
        }

        return result
    }

    _refresh() {
        const actual_molecules = new Set(this._molecules.actual())
        const actual_conditions = new Set(this._conditions.actual())

        this._cross_interactions = this._interactions.all().filter(interaction => interaction.molecules.length > 1 && intersection(new Set(interaction.molecules), actual_molecules).size === interaction.molecules.length)
        this._condition_interactions = this._interactions.all().filter(interaction => intersection(new Set(interaction.conditions), actual_conditions).size > 0)
        this._actual_molecule_interactions = this._interactions.all().filter(interaction => intersection(new Set(interaction.molecules), actual_molecules).size !== 0);
    }

    _refreshIfShould() {
        if (timestamp_ms() - this._last_refresh > 300) {
            this._refresh()
            this._last_refresh = timestamp_ms()
        }
    }

    get(molecule) {
        this._refreshIfShould()

        const recommendations = this._emptyRecommendations()

        const cross_interactions = this._cross_interactions.filter(interaction => interaction.molecules.includes(molecule))
        this._fillRecommendations(cross_interactions, recommendations)

        const condition_interactions = this._condition_interactions.filter(interaction => interaction.molecules.includes(molecule))
        this._fillRecommendations(condition_interactions, recommendations)

        return recommendations
    }

    hasCondition(condition) {
        this._refreshIfShould()
        return this._actual_molecule_interactions.filter(interaction => interaction.conditions.includes(condition)).length > 0;
    }

}

export default MoleculeRecommender