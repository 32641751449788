import IdIndexer from "@/utils/IdIndexer";

class ChecklistManager extends IdIndexer {
    constructor(description) {
        super(description)
        this.enabled = {}
    }

    has(object) {
        return this.enabled[object.id];
    }

    actual() {
        return this.all().filter(c => this.has(c))
    }
}

export default ChecklistManager