<template>
    <div v-if="state.contract">
        <h5 class="my-3">Целевые показатели</h5>

        <div class="card">
            <div class="card-body">


                <div class="row mb-2">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <div class="m-1 mb-2"><small>Систолическое давление</small></div>
                    </div>
                    <div class="col-3">
                        <div class="m-1 mb-2"><small>Диастолическое давление</small></div>
                    </div>
                    <div class="col-3">
                        <div class="m-1 mb-2"><small>Пульс</small></div>
                    </div>
                    <div class="col-3">
                        <div class="m-1"><strong>Верхняя граница</strong></div>
                    </div>
                    <div class="col-3"><input class="form-control form-control-sm m-1" type="number" step="1"
                                              :class="{'is-invalid': sent && !state.contract.targets.max_systolic_pressure}"
                                              v-model="state.contract.targets.max_systolic_pressure"></div>
                    <div class="col-3"><input class="form-control form-control-sm m-1" type="number" step="1"
                                              :class="{'is-invalid': sent && !state.contract.targets.max_diastolic_pressure}"
                                              v-model="state.contract.targets.max_diastolic_pressure"></div>
                    <div class="col-3"><input class="form-control form-control-sm m-1" type="number" step="1"
                                              :class="{'is-invalid': sent && !state.contract.targets.max_pulse}"
                                              v-model="state.contract.targets.max_pulse"></div>

                    <div class="col-3">
                        <div class="m-1"><strong>Нижняя граница</strong></div>
                    </div>
                    <div class="col-3"><input class="form-control form-control-sm m-1" type="number" step="1"
                                              :class="{'is-invalid': sent && !state.contract.targets.min_systolic_pressure}"
                                              v-model="state.contract.targets.min_systolic_pressure"></div>
                    <div class="col-3"><input class="form-control form-control-sm m-1" type="number" step="1"
                                              :class="{'is-invalid': sent && !state.contract.targets.min_diastolic_pressure}"
                                              v-model="state.contract.targets.min_diastolic_pressure"></div>
                    <div class="col-3"><input class="form-control form-control-sm m-1" type="number" step="1"
                                              :class="{'is-invalid': sent && !state.contract.targets.min_pulse}"
                                              v-model="state.contract.targets.min_pulse"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>

export default {
    components: {},
    props: ['was_sent'],
    data() {
        return {
            sent: false
        }
    },
    watch: {
        was_sent: function (newVal) {
            this.sent = newVal
        }
    },
}
</script>