
import {createRouter, createWebHashHistory} from 'vue-router'
import RecommendationManager from "@/components/recommendations/Manager.vue";
import Molecule from "@/components/library/Molecule.vue";

const routes = [
    {path: '/', name: 'index', component: RecommendationManager},
    {path: '/library/molecule/:id', name: 'molecule', component: Molecule, props: true},
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})


export default router