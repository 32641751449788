<template>
    <div v-if="state.contract">
        <h5 class="my-3">Назначения</h5>

        <div class="row">
            <div class="col-12 col-lg-3 my-2" v-for="molecule in molecules" :key="molecule.id">
                <div v-if="state.groups.get_by_id(molecule.group_id)" class="card">
                    <div class="card-body">
                        <h6 class="mb-3">{{ molecule.title }} <span
                            class="text-muted small">{{ state.groups.get_by_id(molecule.group_id).title }}</span></h6>

                        <div class="row mb-3">
                            <label class="col-sm-4 col-form-label"><small>Доза</small></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control form-control-sm"
                                       v-model="state.contract.prescriptions[molecule.id].dosage"
                                       :class="{'is-invalid': was_sent && !state.contract.prescriptions[molecule.id].dosage}">
                                <div class="form-text" v-if="molecule.start_dosage"><small>Начальная доза:
                                    <strong>{{ molecule.start_dosage }}</strong></small></div>
                                <div class="form-text" v-if="molecule.max_dosage"><small>Максимальная доза:
                                    <strong>{{ molecule.max_dosage }}</strong></small></div>
                            </div>
                        </div>

                        <div class="row">
                            <label class="col-sm-4 col-form-label"><small>Раз в день</small></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control form-control-sm"
                                       v-model="state.contract.prescriptions[molecule.id].day_times"
                                       :class="{'is-invalid': was_sent && !state.contract.prescriptions[molecule.id].day_times}">
                                <div class="form-text" v-if="molecule.day_times"><small>Рекомендовано
                                    <strong>{{ molecule.day_times }}</strong></small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>

export default {
    components: {},
    props: ['was_sent', 'molecules'],
    data() {
        return {
            sent: false,
        }
    },
    watch: {
        was_sent: function (newVal) {
            this.sent = newVal
        },
    },
}
</script>