<template>
    <div>
        <div class="container">
            <router-view></router-view>
        </div>
    </div>

</template>

<script>

export default {
    name: 'App',
    components: {},
    data() {

    }
}
</script>

<style>

@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700");
@import "assets/styles/buttons.css";
@import "assets/styles/colors.css";
@import "assets/styles/printing.css";


#app {
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #444;
}

.accent-color {
    color: #006c88;
}

</style>
