import IdIndexer from "@/utils/IdIndexer";

class GroupInteractionsManager extends IdIndexer {
    constructor(description, condition_manager, group_manager) {

        description.forEach((interaction, i, arr) => {
            arr[i].groups = interaction.groups.map(group_id => group_manager.get_by_id(group_id))
            arr[i].conditions = interaction.conditions.map(condition_id => condition_manager.get_by_id(condition_id))
        })

        super(description)
    }
}

export default GroupInteractionsManager