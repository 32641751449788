<template>
    <div class="col-3" v-for="interaction in state.group_recommender.getCurrentList()" :key="interaction.id">
        <div class="card mb-3 my-2">
            <div class="card-header fs-6"
                 :class="{'text-bg-success': interaction.recommendation === 'RECOMMEND', 'text-bg-info': interaction.recommendation === 'RELATIVE_RECOMMEND', 'text-bg-warning': interaction.recommendation === 'RELATIVE_DENY', 'text-bg-danger': interaction.recommendation === 'DENY'}">
                <small>{{
                        interaction.groups.map(m => m.title).join(' + ')
                    }}</small>
            </div>
            <div class="card-body">
                <p v-if="interaction.recommendation === 'RECOMMEND'"><small>Комбинация рекомендована к
                    применению.</small></p>
                <p v-if="interaction.recommendation === 'RELATIVE_RECOMMEND'"><small>Комбинация рекомендована к
                    применению.</small></p>
                <p v-if="interaction.recommendation === 'RELATIVE_DENY'"><small><span
                    v-if="interaction.groups.length > 1">Комбинация не рекомендована</span><span
                    v-else>Группа веществ не рекомендована</span> к применению при:
                    <i>{{ interaction.conditions.map(c => c.title).join(', ') }}</i>.</small></p>
                <p v-if="interaction.recommendation === 'DENY'">
                    <small><span v-if="interaction.groups.length > 1">Комбинация запрещена</span>
                        <span v-else>Группа веществ не рекомендована</span> к применению при:
                        <i>{{ interaction.conditions.map(c => c.title).join(', ') }}</i>.</small></p>
            </div>
        </div>
    </div>
    <div class="col-3" v-for="interaction in state.molecule_recommender.actual()" :key="interaction.id">
        <div class="card mb-3 my-2">
            <div class="card-header fs-6"
                 :class="{'text-bg-warning': interaction.recommendation === 'RELATIVE_DENY', 'text-bg-danger': interaction.recommendation === 'DENY'}">
                <small>{{
                        interaction.molecules.map(m => m.title).join(' + ')
                    }}<span v-if="interaction.freq"> | {{ interaction.freq }}</span><span v-if="interaction.level"> | {{
                            interaction.level
                        }}</span></small>
            </div>
            <div class="card-body">
                <ParagraphSeparator :small="true" :text="interaction.recommendations"/>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
import ParagraphSeparator from "@/components/common/Paragrapher.vue";

export default {
    components: {ParagraphSeparator},
}
</script>