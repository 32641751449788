import api_utils from "../api_utils";

class ContractActions extends api_utils.ActionGroup {
    constructor(http_client, token) {
        super(http_client, token);
    }

    async get() {

        let query = {
            api_token: this.token
        }

        let result;

        try {
            result = await this.client.get('/contract', query);
        } catch (e) {
            console.log(e)
            throw new Error("Ошибка соединения с сервером.")
        }

        let expectedErrors = []

        api_utils.checkForErrors(result, expectedErrors)

        return result.data

    }

    async save(condition_ids, molecule_ids, prescriptions, comments, targets, notify_patient) {

        let query = {
            api_token: this.token
        }

        let data = {
            prescriptions: prescriptions,
            conditions: condition_ids,
            molecules: molecule_ids,
            comments: comments,
            notify_patient: notify_patient,
            targets: targets
        }

        let result;

        try {
            result = await this.client.postJson('/contract', query, data);
        } catch (e) {
            console.log(e)
            throw new Error("Ошибка соединения с сервером.")
        }

        let expectedErrors = []

        api_utils.checkForErrors(result, expectedErrors)

        return result.data

    }
}

export default ContractActions