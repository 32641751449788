import resources from "@/resources"
import api from "@/api"


const applicationPlugin = {
    install(app) {
        app.config.globalProperties.state = resources.state
        app.config.globalProperties.api = api
        app.config.globalProperties.event_bus = resources.event_bus
        app.config.globalProperties.host = process.env.VUE_APP_JSHOST

        if (window.MODE) {
            app.config.globalProperties.mode = window.MODE
        }
        else {
            app.config.globalProperties.mode = "full"
        }


        resources.state.init().then(() => {
            resources.event_bus.emit("loaded")
        })
    }
}

export default applicationPlugin;